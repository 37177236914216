/* eslint-disable react/jsx-no-literals,react/no-unescaped-entities,max-len */
import React from 'react';
import styles from '../FrenchText/FrenchText.module.scss';

const EnglishText: React.FC = () => (
  <>
    <h2>
      ONOFF TELECOM PERSONAL DATA PROTECTION POLICY
    </h2>

    <h2>Charter of use of cookies and tracers</h2>

    <p>Updated on : 30 June 2021</p>

    <p>
      The purpose of this personal data protection policy is to inform You about how We use Your
      personal data, and protect the data that You provide to Us during use of :
    </p>

    <ul>
      <li>
        any application downloadable from the Apple Store, Google Play Store or Microsoft AppSource
        application distribution platforms (regardless of any application,
        the «
        <strong>Application</strong>
        »)
      </li>
      <li>
        any website or internet application (without distinction for any website or internet
        application, the «
        <strong>
          Site
        </strong>
        »)
      </li>
    </ul>

    <p>
      operated by ONOFF TELECOM.
    </p>
    <p>
      We therefore invite You to read this personal data protection policy carefully.
    </p>
    <p>
      For the purposes of this policy, the definitions of the General Terms and Conditions of Use
      apply (
      <a href="https://www.onoff.app/terms-conditions/">
        https://www.onoff.app/terms-conditions/
      </a>
      {' '}
      &
      {' '}
      <a href="https://onoffbusiness.com/terms-conditions/">
        https://onoffbusiness.com/terms-conditions/
      </a>
      ).
    </p>

    <h2>ONOFF TELECOM PERSONAL DATA PROTECTION POLICY</h2>

    <h3>1. Object</h3>

    <p>
      This personal data protection policy applies to all personal data of Users of the Application
      processed by ONOFF TELECOM, a simplified joint-stock company, registered in the Paris Trade
      and Companies Register under number 832 235 253 and having its registered office at 26
      boulevard de Bonne Nouvelle 75010, Paris, France , as data controller, under the conditions
      provided herein.
    </p>

    <p>
      ONOFF TELECOM attaches particular importance to the protection of Your personal data and
      respect for Your privacy. This Privacy Policy (together with Our Cookie and Tracer Policy and
      Our Terms and Conditions of Use and any documents referred to therein) is intended to inform
      You of how We process the personal data that You provide to Us and that We collect in
      connection with Your use of the Application , Our Site and services. We invite You to read
      this document carefully to understand Our practices regarding the processing of Your personal
      data and to know Your rights in this regard.
    </p>

    <p>
      This Privacy Policy applies to the Application, the Site and all Services published by ONOFF
      TELECOM that You use (excluding services provided and operated by third parties, in which
      case, it is the privacy policy of these third parties that applies and not these
      stipulations).
    </p>

    <h3>2. What data is collected and processed by Onoff Telecom?</h3>

    <p>
      ONOFF TELECOM may collect and use personal data about You. This is information that You
      transmit directly to Us or data that We collect automatically.
    </p>

    <p>
      This includes information collected when You download and use the Application, in particular
      when creating the User Account, purchasing Numbers and using features or when you use the
      Site.
    </p>

    <p>
      When you create your User Account or subscribe to one of the Services, ONOFF TELECOM will ask
      you for the following information: Your surname, first name, password, email address, and if
      applicable telephone number, identity documents or proving Your place of residence when the
      applicable regulations require it.
    </p>

    <p>
      As part of the Onoff Business Services, some of this data is collected indirectly and is
      transmitted to ONOFF TELECOM by your Company: surname, first name and email address.
    </p>

    <p>
      Only the provision of your surname, first name, password, email address (and where applicable
      telephone number, identity documents or proving Your place of residence when the applicable
      regulations require it) is mandatory for the creation of your account.
    </p>

    <p>
      Failing to communicate them, ONOFF TELECOM will not be able to provide You with the Services
      offered by the Application and You will not be able to create a User Account.
    </p>

    <p>
      Subsequently, ONOFF TELECOM may collect and process the following information, in the context
      of your use of the Application and your purchases:
    </p>

    <ul>
      <li>
        The telephone numbers You have acquired;
      </li>

      <li>
        Your traffic data (technical data allowing the transmission of communications, the duration
        of the call, the numbers called, the category in which they were placed, etc.);
      </li>

      <li>
        The content of instant messages, SMS, MMS;
      </li>

      <li>
        Your address book (contact details stored on Your terminal), with Your authorization;
      </li>

      <li>
        The electronic identification data of Your terminal (SIM card number, IMEI number, cookies,
        etc.);
      </li>

      <li>
        Your geolocation data, device identifiers, and standard identifier of detected Wi-Fi
        networks.
      </li>
    </ul>

    <p>
      When You contact customer service and support, report a problem with Our Services, request
      assistance or exercise your rights over your personal data, ONOFF TELECOM will also collect
      the following personal data:
    </p>

    <ul>
      <li>
        Date and time of the request;
      </li>

      <li>
        Nature and reason for the request.
      </li>
    </ul>

    <p>
      In addition, proof of Your identity may be requested in the context of the exercise of Your
      rights over Your personal data for reasons of security and confidentiality.
    </p>

    <p>
      This information is necessary for processing Your requests.
    </p>

    <p>
      When you use the Application and the Services published by ONOFF, We collect information about
      Your activity on the Application:
    </p>

    <ul>
      <li>
        Date of registration;
      </li>

      <li>
        Dates of connections;
      </li>

      <li>
        Number of transactions;
      </li>

      <li>
        Notifications received;
      </li>

      <li>
        IP Addresses ;
      </li>

      <li>
        Notification preferences, etc.
      </li>
    </ul>

    <p>
      When you access the Services and use the Application and its features, information is
      collected and processed about how You use them. Most of this information collected by ONOFF
      TELECOM is necessary for the provision of the Services. Without this data, ONOFF TELECOM will
      not be able to provide the Services related to the Application.
    </p>

    <h3>3. How is Your data used by Onoff Telecom?</h3>

    <p>
      The processing of Your personal data has the following purposes:
    </p>

    <ul>
      <li>
        Provide you with an Application offering innovative communications and content sharing
        services;
      </li>

      <li>
        Create, use and maintain Your User Account;
      </li>

      <li>
        Manage Your Numbers, contacts, conversation groups, etc.;
      </li>

      <li>
        Modify and customize the functions and features of the Application;
      </li>

      <li>
        Receive and process transactions made through the Application;
      </li>

      <li>
        Provide you with Our assistance and resolve any problems encountered while using the
        Application;
      </li>

      <li>
        Notify you of updates to the Application and our offers and Services;
      </li>

      <li>
        Solicit Your feedback or participation in surveys in order to improve Our Services;
      </li>

      <li>
        Comply with Our legal, regulatory and administrative obligations;
      </li>

      <li>
        Avoid potentially prohibited or illegal activities.
      </li>
    </ul>

    <p>
      Below you will find the legal bases corresponding to the processing purposes:
    </p>

    <table cellPadding="0" cellSpacing="0" className={styles.Table1}>
      <tr className={styles.Table11}>
        <td className={styles.Table1_A1}>
          <strong>Legal bases</strong>
        </td>
        <td className={styles.Table1_A1}>
          <strong>Purposes of processing</strong>
        </td>
      </tr>

      <tr className={styles.Table11}>
        <td className={styles.Table1_A2}>
          <strong>Performance of the contract or pre-contractual measures</strong>
        </td>
        <td className={styles.Table1_A2}>
          <ul>
            <li>
              Provision of communications and content sharing services;
            </li>

            <li>
              Creation, use and storage of Your User Account;
            </li>

            <li>
              Management of Your Numbers, contacts, chat groups, etc.;
            </li>

            <li>
              Modification and customization of the functions and features of the Application;
            </li>

            <li>
              Receipt and processing of transactions carried out via the Application;
            </li>

            <li>
              Assistance in case of problems encountered while using the Application;
            </li>

            <li>
              Soliciting Your opinions or participating in surveys to improve Our services.
            </li>
          </ul>
        </td>
      </tr>

      <tr className={styles.Table11}>
        <td className={styles.Table1_A3}>
          <strong>Legal obligations</strong>
        </td>
        <td className={styles.Table1_B3}>
          <ul>
            <li>
              Compliance with legal, administrative and regulatory obligations
            </li>
          </ul>
        </td>
      </tr>

      <tr className={styles.Table11}>
        <td className={styles.Table1_A2}>
          <strong>Legitimate interest</strong>
        </td>
        <td className={styles.Table1_A2}>
          <ul>
            <li>
              Commercial prospecting In order to offer you services that correspond to your needs.
            </li>
            <li>
              Avoid potentially prohibited or illegal activities. In order to protect the interests
              of Users who use the Application in compliance with the Terms of Use and regulations
              as well as the interests of ONOFF TELECOM.
            </li>
          </ul>
        </td>
      </tr>
    </table>

    <br />

    <h3>4. In what cases may we share Your personal data?</h3>

    <p>
      <h6>a. Communication with Our affiliates, partners and service providers</h6>

      <p>
        We never communicate Your personal data to third parties without your prior and express
        consent, except in the cases exhaustively listed below.
      </p>

      <p>
        ONOFF TELECOM may be required to communicate Your personal data to third parties acting on
        its behalf, for the sole purposes of the operation of Our services, and in particular to Our
        service providers or subcontractors, responsible for ensuring the management, hosting,
        maintenance and administration of the Application and the services and transactions
        associated with it.
      </p>

      <p>
        Your personal data will be communicated to them in compliance with the provisions of the
        General Data Protection Regulation ( GDPR No. 2016/679) and within the framework of
        agreements to ensure compliance with this policy and the confidentiality of Your personal
        data.
      </p>

      <p>
        In the event that ONOFF TELECOM is required to merge, to be acquired or is transferred to
        another company, all or part of its activities, We may then share Your personal data with
        this company, including with companies established outside the European Union, under the
        conditions and to the extent permitted by the applicable regulations and in compliance with
        these terms.
      </p>

      <p>
        In addition, We may be obliged to disclose Your personal data in the context of a law or
        regulatory provision in force, a court order or a regulation, or if such disclosure is
        necessary in the context of an investigation, or a procedure, in the national territory or
        abroad.
      </p>
    </p>

    <p>
      <h6>b. Transfers to countries outside the European Union</h6>

      <p>
        Your use of the Application entails the transmission of Your personal data (surname, first
        name, email address, use of the features of the Application, etc.) for the purposes of
        hosting, administering and improving the performance of the Application and the services and
        transactions associated with it, to service providers located in countries that are not
        members of the European Union, in particular in the United States , whose legislation on the
        protection of personal data differs from that in force in the European Union.
      </p>

      <p>
        ONOFF TELECOM has put in place the guarantees provided for by the applicable regulations to
        ensure a sufficient level of protection of personal data by framing the transfers by
        Standard Contractual Clauses established by the European Commission.
      </p>
    </p>

    <h3>5. How is Your personal information protected by Onoff Telecom? </h3>

    <p>
      ONOFF TELECOM takes all necessary organizational and technical measures to protect Your
      personal data against accidental or intentional manipulation, loss, destruction, communication
      or against unauthorized access to Your data.
    </p>

    <h3>6. What are the cases in which Onoff Telecom is likely to contact You?</h3>

    <p>
      <h6>a. Informations à propos des produits et services d’ONOFF TELECOM.</h6>

      <p>
        ONOFF TELECOM may send You offers relating to the products, services and activities offered
        by ONOFF TELECOM. You can set or change Your choices regarding these communications at any
        time:
      </p>

      <ul>
        <li>
          by using the unsubscribe links inserted in the Settings/Support section of the
          Application;
        </li>

        <li>
          by sending us an email to the following address: support@onoffapp.com from the email
          address to which Your User Account is attached;
        </li>

        <li>
          by using the unsubscribe links inserted in Our newsletters or prospecting emails.
        </li>
      </ul>
    </p>

    <p>
      <h6>b. Function of the Services</h6>

      <li>
        ONOFF TELECOM may periodically ask You to provide information about Your experiences, which
        will be used to evaluate and improve the quality of its services, or ask You to rate the
        application and the new features of the Application. You are under no circumstances or under
        any circumstances obliged to provide such data.
      </li>

      <li>
        ONOFF TELECOM may also contact You to inform You of any anomalies and problems related to
        its services, as well as to provide You with information relating to Your subscription (for
        example, notification of the expiry of Your subscription to an ONOFF TELECOM number).
      </li>
    </p>

    <h3>7. What are your rights and how to exercise them?</h3>

    <p>
      In accordance with Law No. 78-17 of 6 January 1978, known as the Data Protection Act (the
      "Data Protection Act") and Regulation (EU) 2016/679 of the European Parliament and of the
      Council of 27 April 2016, on the protection of natural persons with regard to the processing
      of personal data and on the free movement of such data (the "GDPR"), each User has a right of
      access, rectification and, where applicable, a right to portability and erasure on his
      personal data, as well as to oppose the processing or its limitation and the right to define
      guidelines relating to the fate of his personal data after his death.
    </p>

    <p>
      As part of the processing we carry out You have:
    </p>

    <ul>
      <li>
        a right of access: You have, under the terms and conditions of Article 15 of the GDPR, the
        right to obtain the communication - in an accessible form - of the personal data concerning
        You that We process and request that a copy be communicated to You;
      </li>

      <li>
        a right of rectification: You have, in the terms and conditions of Article 16 of the GDPR,
        the right to require ONOFF TELECOM to rectify, complete, update or erase personal data
        concerning You that are inaccurate, incomplete, equivocal, outdated (in particular in the
        event of a change in situation) or whose use, communication or storage is prohibited , ;
      </li>

      <li>
        a right of erasure: You can request the deletion of your personal data under the conditions
        provided for in Article 17 of the GDPR, in particular when they are no longer necessary;
      </li>

      <li>
        a right to restriction of processing: You can ask Us to limit the processing of your
        personal data under the conditions provided for in Article 18 of the GDPR;
      </li>

      <li>
        a right to data portability: You may, under the terms and conditions of Article 20 of the
        GDPR, retrieve in a structured, commonly used and machine-readable format the data that You
        have provided to Us when these are necessary for the contract or when You have consented to
        the use of such data; you have the right to transmit them to another controller,
      </li>

      <li>
        a right of withdrawal: You have the right to withdraw the consent given for processing based
        on this basis. This withdrawal applies to the future and does not call into question the
        lawfulness of the treatments already carried out;
      </li>

      <li>
        a right to object: You can object to the processing of your personal data in particular for
        the purposes of commercial prospecting under the conditions set out in Article 21 of the
        GDPR.
      </li>
    </ul>

    <p>
      You can, subject to proving your identity, exercise these rights by sending us a message to
      the following address:
      {' '}
      <a href="mailto:support@onoffapp.com">
        support@onoffapp.com.
      </a>
      {' '}
      or by sending a letter to the following postal
      address: for the attention of the Data Protection Officer, ONOFF TELECOM - 26 boulevard de
      Bonne Nouvelle - 75010 Paris – France. For reasons of confidentiality, in case of doubt as to
      the identity of the applicant, proof of identity may be requested.
    </p>

    <p>
      In addition, you can lodge a complaint with a competent national data protection supervisory
      authority.
    </p>

    <p>
      Note for French users:
    </p>

    <p>
      You have, in accordance with Article 85 of the Data Protection Act, a right to define
      guidelines relating to the fate of Your personal data in the event of death that You can
      exercise by sending Us a message to the following address:
      {' '}
      <a href="mailto:support@onoffapp.com">
        support@onoffapp.com.
      </a>
      {' '}
      .
    </p>

    <p>
      You can also object to being canvassed by telephone by subscribing to the Bloctel list on the
      {' '}
      <a href="www.bloctel.fr">
        www.bloctel.fr
      </a>
      {' '}
      site.
    </p>

    <h3>8. How long do we keep your personal data?</h3>

    <p>
      In accordance with the applicable regulations, We store Your personal data for a period that
      does not exceed what that necessary for the pour processing purposes.
    </p>

    <p>
      The personal data that We collect when You download and use the Application are kept for the
      duration necessary to manage the Subscribed Services and then for 5 years in archives.
    </p>

    <p>
      Traffic data collected in connection with Your use of the Communications Services is retained
      in accordance with the periods prescribed by applicable law.
    </p>

    <p>
      If You stop using Your User Account for a period of two (2) years, it will go into an inactive
      state and will be deleted. You can avoid this by logging into Your User Account at least once
      every two (2) years. Before proceeding with the deletion of Your User Account, we will send
      you an email notification in order to give you the opportunity to keep Your User Account.
    </p>

    <p>
      When you delete Your User Account or when it is deleted due to inactivity, We retain some of
      Your personal data necessary to ensure compliance with Our legal and regulatory obligations.
      Other personal data associated with Your User Account is deleted.
    </p>

    <h3>9. If you are under the age of 18</h3>

    <p>
      If You are under the age of 18, Please ask You to seek the consent of Your parents or legal
      representatives before providing Us with data about You.
    </p>

    <p>
      They must contact Us at the address
      {' '}
      <a href="mailto:support@onoffapp.com">
        support@onoffapp.com.
      </a>
      {' '}
      in order to provide Us with their contact details (name, email address, telephone number) so
      that they confirm their agreement and so that We can contact them in case of problems or for
      any further information. Otherwise, You must refrain from using the Application.
    </p>

    <h3>10. Notifications</h3>

    <p>
      Your mobile device and the Application must be set up so that You are informed of incoming
      calls, chats and messages when you are not using the Application. If you do not wish to
      benefit from this feature, you can change the settings of Your device to no longer receive
      notifications from the Application.
    </p>

    <h3>11. Changes to this Policy</h3>

    <p>
      We reserve the right to change this Privacy Policy at any time. Therefore, we recommend that
      you regularly review this Policy to inform You of any updates to its content.
    </p>

    <p>
      This policy was last amended on June 30, 2021
    </p>

    <h3>12. How do I contact Onoff Telecom?</h3>

    <p>
      You have the possibility to contact us for any question relating to the use of Your data, or
      to exercise Your rights at the following email address:
      {' '}
      <a href="mailto:support@onoffapp.com">
        support@onoffapp.com.
      </a>
      {' '}
      or by sending a letter to the following postal address: to the attention of the Data
      Protection Officer, ONOFF TELECOM - 26 boulevard de Bonne Nouvelle - 75010 Paris – France.
    </p>

    <h2>Charter of use of cookies and tracers</h2>

    <p>
      ONOFF TELECOM attaches particular importance to respecting your privacy and invites you to
      read this Cookie and Tracers Policy ("Charter") to understand how We use cookies and tracers
      on the Application and the websites we operate.
    </p>

    <p>
      The Charter is intended to supplement the Privacy Policy and the General Conditions of Use of
      the Application.
    </p>

    <p>
      Terms beginning with a capital letter and which are not defined in the Charter have the
      meaning of the terms defined in the General Conditions of Use.
    </p>

    <h3>1. SCOPE OF THE CHARTER</h3>

    <p>The Charter is intended to apply to the following media:</p>

    <ul>
      <li>
        Any website operated by ONOFF TELECOM (interchangeably for any website, the "
        <strong>Site</strong>
        ");
      </li>

      <li>
        Any mobile application operated by ONOFF TELECOM available in particular on the Apple App
        Store and the Google Play Store (regardless of any application, the "
        <strong>Application</strong>
        ").
      </li>

      <li>
        Any application operated by ONOFF TELECOM usable by means of an Internet browser (Chrome,
        Edge, Firefox ...) or any solution allowing the use of Numbers through applications
        belonging to third parties (Microsoft Teams or Zoom).
      </li>

    </ul>

    <h3>2. Cookies and tracers</h3>

    <p>
      The use of the Site and the Application implies the use of cookies and tracers on Your
      terminal. You can refuse the use of cookies and tracers from any terminal by contacting us via
      the following email address: support@onoffapp.com . You should be aware that certain features
      of the Site and The Application are only available by using cookies and trackers, and that if
      You choose not to accept them, You expose Yourself to the possibility that these features may
      be inaccessible to You.
    </p>

    <h4>2.1. What are cookies and tracers?</h4>

    <p>
      Cookies are small text files that are saved on Your device, when using certain features of the
      Application. For more information on cookies, we invite You to consult:
      {' '}
      <a href="http://www.allaboutcookies.org/fr/http://www.allaboutcookies.org/fr/">
        http://www.allaboutcookies.org/fr/http://www.allaboutcookies.org/fr/
      </a>
      .
    </p>

    <p>
      Security and functional cookies are strictly necessary for navigation on the Site and the
      Application, so You cannot refuse the deposit of these cookies.
    </p>

    <h4>2.2. How are cookies and tracers used by ONOFF TELECOM?</h4>

    <p>
      ONOFF TELECOM uses audience measurement cookies and tracers to produce and generate anonymous
      statistics of attendance of the Site and the Application, as well as global data on the use of
      the Application.
    </p>

    <h3>3. WHY DOES ONOFF TELECOM USE COOKIES AND TRACERS</h3>

    <p>
      ONOFF TELECOM uses cookies on its Site and tracers on its Application for the purposes listed
      below.
    </p>

    <p>
      For these purposes, ONOFF TELECOM may allow third-party partners to deposit cookies or access
      Identifiers and cookies on the hard drive of Your terminal when You visit Our Site or use Our
      Application.
    </p>

    <p>
      Your consent is required before any deposit of cookies except for those meeting technical
      needs, these being strictly necessary for navigation on the Site. Similarly, your consent is
      required for the use of tracers on the Application and the collection of Identifiers by
      tracing tools. Your consent is not required only in the event that the tracers are strictly
      necessary for the operation or security of the Application.
    </p>

    <h4>3.1 On the Site</h4>

    <p>
      The cookies deposited allow the Site to provide You with a suitable page, to recognize Your
      previous settings, to support You and to facilitate Your navigation.
    </p>

    <p>
      When you visit Our Site, cookies are placed on the hard drive of your terminal. Some are
      necessary for (i) the operation and security of Our Site and others are used, where You
      consent, for (ii) statistical purposes.
    </p>

    <p>
      <strong>
        Technical requirements:
      </strong>
    </p>

    <p>
      When you visit the Site security cookies are deposited. They can be used for authentication or
      security purposes. They are mandatory to allow certain features of the Site and to ensure its
      security.
    </p>

    <p>
      Functional cookies are also deposited and allow you to improve and optimize your experience on
      the Site.
    </p>

    <p>
      <strong>
        Security and functional cookies are strictly necessary for navigation on the Site, so you
        cannot refuse the deposit of these cookies.
      </strong>
    </p>

    <table cellPadding="0" cellSpacing="0" className={styles.Table1}>
      <tr className={styles.Table11}>
        <td className={styles.Table1_A1}>
          <strong>Cookie name</strong>
        </td>
        <td className={styles.Table1_A1}>
          <strong>enterprise</strong>
        </td>
        <td className={styles.Table1_A1}>
          <strong>domain</strong>
        </td>
        <td className={styles.Table1_A1}>
          <strong>purpose</strong>
        </td>
        <td className={styles.Table1_A1}>
          <strong>duration</strong>
        </td>
      </tr>

      <tr className={styles.Table11}>
        <td className={styles.Table1_A2}>
          User locale
        </td>
        <td className={styles.Table1_A2}>
          Onoff
        </td>
        <td className={styles.Table1_A2}>
          <a href="web.onoffbusiness.com">web.onoffbusiness.com</a>
        </td>
        <td className={styles.Table1_A2}>
          With this cookie it is possible to store the user's preferred language. We can therefore
          display the pages of the site in this language.
        </td>
        <td className={styles.Table1_A2}>
          Session (deleted after closing the browser)
        </td>
      </tr>

      <tr className={styles.Table11}>
        <td className={styles.Table1_A3}>
          User id
        </td>
        <td className={styles.Table1_A3}>
          Onoff
        </td>
        <td className={styles.Table1_A3}>
          <a href="web.onoffbusiness.com">web.onoffbusiness.com</a>
        </td>
        <td className={styles.Table1_A3}>
          This cookie makes it possible to identify the user without the need to log in manually.
        </td>
        <td className={styles.Table1_A3}>
          1 an
        </td>
      </tr>

      <tr className={styles.Table11}>
        <td className={styles.Table1_A2}>
          Checkout
        </td>
        <td className={styles.Table1_A2}>
          Checkout
        </td>
        <td className={styles.Table1_A2}>
          <a href="https://www.checkout.com/">https://www.checkout.com/</a>
        </td>
        <td className={styles.Table1_A2}>
          These cookies make it possible to identify the information relating to the payment such
          as: the user within checkout, the geographical areae,..
        </td>
        <td className={styles.Table1_A2}>
          <ul>
            <li>
              _gcl_au, _gid, RT (1 day)
            </li>
            <li>
              optimizelyEndUserId (6 months)
            </li>
            <li>
              hjid (1 an)
            </li>
            <li>
              OptanonAlertBoxClosed (1an)
            </li>
            <li>
              _hp2_id (13 months)
            </li>
            <li>
              OptanonConsent, (1 an)
            </li>
            <li>
              _uetvid (16 days)
            </li>
            <li>
              ei_client_id (730 days)
            </li>
            <li>
              _ga (2 years)
            </li>
          </ul>
        </td>
      </tr>

      <tr className={styles.Table11}>
        <td className={styles.Table1_A3}>
          Zendesk
        </td>
        <td className={styles.Table1_A3}>
          Zendesk
        </td>
        <td className={styles.Table1_A3}>
          <a href="https://www.zendesk.com/">https://www.zendesk.com/</a>
        </td>
        <td className={styles.Table1_A3}>
          This cookie allows the support to retrieve information relating to the user in order to
          better assist him
        </td>
        <td className={styles.Table1_A3}>
          -
        </td>
      </tr>

      <tr className={styles.Table11}>
        <td className={styles.Table1_A2}>
          Recaptcha
        </td>
        <td className={styles.Table1_A2}>
          Google
        </td>
        <td className={styles.Table1_A2}>
          <a href="https://www.google.com/recaptcha/about/">
            https://www.google.com/recaptcha/about/
          </a>
        </td>
        <td className={styles.Table1_A2}>
          This cookie makes it possible to check if the user is not a "bot" (robot)
        </td>
        <td className={styles.Table1_A2}>
          6 months
        </td>
      </tr>
    </table>

    <p>
      <strong>
        Analytical needs:
      </strong>
    </p>

    <p>
      ONOFF TELECOM uses audience measurement Cookies that are used to obtain information on the
      users' journey, to analyze traffic on Our Site and for audience measurement purposes (for
      example, cookies collect information about the pages visited: number of visits, etc.). The
      information collected is anonymous and aggregated. This allows Us to improve our Site
      according to the requests and needs of Users.
    </p>

    <p>
      <strong>
        Your consent is required for the deposit of cookies for analytical purposes.
      </strong>
    </p>

    <table cellPadding="0" cellSpacing="0" className={styles.Table1}>
      <tr className={styles.Table11}>
        <td className={styles.Table1_A1}>
          <strong>Cookie name</strong>
        </td>
        <td className={styles.Table1_A1}>
          <strong>enterprise</strong>
        </td>
        <td className={styles.Table1_A1}>
          <strong>domain</strong>
        </td>
        <td className={styles.Table1_A1}>
          <strong>purpose</strong>
        </td>
      </tr>

      <tr className={styles.Table11}>
        <td className={styles.Table1_A2}>
          Snap Pixel
        </td>
        <td className={styles.Table1_A2}>
          Snapchat
        </td>
        <td className={styles.Table1_A2}>
          <a
            href="https://forbusiness.snapchat.com/measurement/"
          >
            https://forbusiness.snapchat.com/measurement/
          </a>
        </td>
        <td className={styles.Table1_A2}>
          With the Snap Pixel, advertisers can follow their customers' journey.
        </td>
      </tr>

      <tr className={styles.Table11}>
        <td className={styles.Table1_A3}>
          Taboola
        </td>
        <td className={styles.Table1_A3}>
          Taboola
        </td>
        <td className={styles.Table1_A3}>
          <a href="https://www.taboola.com/fr/">https://www.taboola.com/fr/</a>
        </td>
        <td className={styles.Table1_A3}>
          The Taboola Pixel gathers data to allow us to have a better understanding of the actions
          that users take on our site.
        </td>
      </tr>

      <tr className={styles.Table11}>
        <td className={styles.Table1_A2}>
          Facebook Pixel
        </td>
        <td className={styles.Table1_A2}>
          Facebook
        </td>
        <td className={styles.Table1_A2}>
          <a
            href="https://www.facebook.com/business/gdpr"
          >
            https://www.facebook.com/business/gdpr
          </a>
        </td>
        <td className={styles.Table1_A2}>
          Identifies visitors from Facebook posts
        </td>
      </tr>

      <tr className={styles.Table11}>
        <td className={styles.Table1_A3}>
          Google Ads
        </td>
        <td className={styles.Table1_A3}>
          Google
        </td>
        <td className={styles.Table1_A3}>
          <a href="https://www.google.fr">https://www.google.fr</a>
        </td>
        <td className={styles.Table1_A3}>
          Google Ads is the advertising agency of the Google search engine. It allows you to carry
          out ad campaigns via an ad system and to display these ads in search engine results based
          on keywords typed by internet users.
        </td>
      </tr>

      <tr className={styles.Table11}>
        <td className={styles.Table1_A2}>
          Google Analytics
        </td>
        <td className={styles.Table1_A2}>
          Google
        </td>
        <td className={styles.Table1_A2}>
          <a href="https://www.google.fr">https://www.google.fr</a>
        </td>
        <td className={styles.Table1_A2}>
          Allows you to analyze the statistics of consultation of our site
        </td>
      </tr>

      <tr className={styles.Table11}>
        <td className={styles.Table1_A3}>
          Google Conversion Tracking
        </td>
        <td className={styles.Table1_A3}>
          Google
        </td>
        <td className={styles.Table1_A3}>
          <a href="https://www.google.fr">https://www.google.fr</a>
        </td>
        <td className={styles.Table1_A3}>
          Google conversion tracking usage statistics
        </td>
      </tr>

      <tr className={styles.Table11}>
        <td className={styles.Table1_A2}>
          Diffuse nos annonces publicitaires sur le réseau Google
        </td>
        <td className={styles.Table1_A2} />
        <td className={styles.Table1_A2}>
          <a
            href="https://privacy.google.com/intl/fr_fr/businesses/compliance/#!?modal_active=none"
          >
            https://privacy.google.com/intl/fr_fr/businesses/compliance/#!?modal_active=none
          </a>
        </td>
        <td className={styles.Table1_A2}>
          Runs our ads on the Google network
        </td>
      </tr>

      <tr className={styles.Table11}>
        <td className={styles.Table1_A3}>
          Bing
        </td>
        <td className={styles.Table1_A3}>
          Microsoft
        </td>
        <td className={styles.Table1_A3}>
          <a
            href="https://www.bing.fr"
          >
            https://www.bing.fr
          </a>
        </td>
        <td className={styles.Table1_A3}>
          Measures the effectiveness of sponsored campaigns on Bing
        </td>
      </tr>

      <tr className={styles.Table11}>
        <td className={styles.Table1_A2}>
          Linkedin
        </td>
        <td className={styles.Table1_A2}>
          Linkedin
        </td>
        <td className={styles.Table1_A2}>
          <a
            href="https://www.linkedin.com"
          >
            https://www.linkedin.com
          </a>
        </td>
        <td className={styles.Table1_A2}>
          Identifies visitors from LinkedIn
        </td>
      </tr>
    </table>

    <h4>3.2 About the Application</h4>

    <p>
      There are no cookies deposited by mobile applications. On the other hand, the Application
      accesses and collects the unique Identifiers of Your device in order to recognize it from one
      visit to the next.
    </p>

    <p>
      Internal and partner tools as well as tracers have been installed on the Application in order
      to access and collect Identifiers.
    </p>

    <p>
      These tracers allow ONOFF TELECOM to:
    </p>

    <ul>
      <li>
        ensure the proper functioning of the Application,
      </li>

      <li>
        Make certain features work.
      </li>

      <li>
        if You have consented, use them for advertising or analytical purposes.
      </li>
    </ul>

    <p>
      <strong>
        Technical requirements:
      </strong>
    </p>

    <p>
      When You consult the Application so-called security tracers collect and process data from Your
      device. They can be used for authentication or security purposes. They are mandatory to allow
      certain features of the Application and to ensure its security.
    </p>

    <p>
      So-called functional tracers are also used and allow to improve and optimize Your experience
      on the Application or are necessary for the use of certain features.
    </p>

    <p>
      Security and functional tracers are strictly necessary for the provision of services on the
      Application, so you may not refuse the collection of Identifiers and the use of security or
      functional tracers.
    </p>

    <p>
      <strong>
        Analytical needs:
      </strong>
    </p>

    <p>
      ONOFF TELECOM uses audience measurement trackers to obtain information on the users' journey,
      to analyze traffic on the Application and for audience measurement purposes. This allows ONOFF
      TELECOM to improve the Application according to the requests and needs of users.
    </p>

    <p>
      Your consent is requested for the collection of Identifiers and the use of tracers for
      analytical purposes.
    </p>

    <table cellPadding="0" cellSpacing="0" className={styles.Table1}>
      <tr className={styles.Table11}>
        <td className={styles.Table1_A1}>
          <strong>Plotter name</strong>
        </td>
        <td className={styles.Table1_A1}>
          <strong>enterprise</strong>
        </td>
        <td className={styles.Table1_A1}>
          <strong>purpose</strong>
        </td>
      </tr>

      <tr className={styles.Table11}>
        <td className={styles.Table1_A2}>
          Firebase Analytics
        </td>
        <td className={styles.Table1_A2}>
          Google
        </td>
        <td className={styles.Table1_A2}>
          Application and audience metrics, to track user actions within the app to improve the user
          experience, collect metrics to analyze feature success, enable features for specific
          audiences, collect user IDs to be able to target specific audiences.
        </td>
      </tr>

      <tr className={styles.Table11}>
        <td className={styles.Table1_A3}>
          Google Analytics
        </td>
        <td className={styles.Table1_A3}>
          Google
        </td>
        <td className={styles.Table1_A3}>
          Application and audience metrics, to track user actions within the app to improve the user
          experience, collect metrics to analyze feature success, enable features for specific
          audiences, collect user IDs to be able to target specific audiences.
        </td>
      </tr>

      <tr className={styles.Table11}>
        <td className={styles.Table1_A2}>
          Branch analytics
        </td>
        <td className={styles.Table1_A2}>
          Branch.io
        </td>
        <td className={styles.Table1_A2}>
          Application and audience metrics, to track user actions within the app to improve the user
          experience, collect metrics to analyze feature success, enable features for specific
          audiences, collect user IDs to be able to target specific audiences.
        </td>
      </tr>
    </table>

    <h3>4. MANAGEMENT OF Cookies and Tracers</h3>

    <h4>4.1 On the website</h4>

    <p>
      When You first arrive on the Site, a cookie banner offers you to accept or refuse Web Cookies
      that are not essential to the stable operation of the Site.
    </p>

    <p>
      In the absence of withdrawal of Your consent, it is valid for a period of 6 months. During
      this period, the cookie banner will no longer be presented to You during Your visits to the
      Site. When the period has elapsed, the cookie banner will be displayed again and will offer
      you to accept or refuse the deposit of cookies.
    </p>

    <p>
      The consent You provide for the deposit of analytical cookies on the Site may be withdrawn at
      any time. All you have to do is click on the link provided for this purpose.
    </p>

    <p>
      Please note that ONOFF TELECOM is not able to delete third-party cookies that have been
      deposited prior to the withdrawal of Your consent.
    </p>

    <p>
      The withdrawal of Your consent will make it impossible for ONOFF TELECOM to read and use the
      information contained in the cookies already deposited on Your browser.
    </p>

    <p>
      However, it is also possible for You to delete these cookies directly via Your browser.
    </p>

    <p>
      <strong>
        WE DO NOT RECOMMEND THAT YOU BLOCK AND DISABLE ALL COOKIES IN YOUR BROWSER SETTINGS.
      </strong>
      {' '}
      Indeed, if you block all cookies, Our Site may not function properly and it is possible that
      You may not be able to use some of its features.
    </p>

    <h4>4.2 About the Application</h4>

    <p>
      When registering on the Application, a consent screen for tracers offers You to accept, refuse
      or configure the use of tracers that are not essential to the stable operation of the
      Application
    </p>

    <p>
      In case of new connection on the Application after a disconnection, the screen will be
      displayed again and will offer You to accept, refuse or configure the use of tracers.
    </p>

    <p>
      The consent You provide for the use of analytics and advertising trackers may be withdrawn at
      any time.
    </p>

    <h3>5. How do I contact Onoff Telecom?</h3>

    <p>
      You have the possibility to contact us for any question relating to the use of cookies and
      tracers at the following email address:
      {' '}
      <a href="mailto:support@onoffapp.com">support@onoffapp.com</a>
      {' '}
      or by sending a letter to the following postal address: to the attention of the Data
      Protection Officer, ONOFF TELECOM - 26 boulevard de Bonne Nouvelle - 75010 Paris – France.
    </p>
  </>
);

export default EnglishText;
