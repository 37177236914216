import React from 'react';
import { IndexProps } from 'components/layout';
import styles from './PrivacyPolicy.module.scss';
import EnglishText from './EnglishText';
import FrenchText from './FrenchText';

const PrivacyPolicy: React.FC<IndexProps> = ({ pageContext }) => (
  <div className={styles.container}>
    <div className={styles.wrapper}>
      {pageContext.language === 'fr' ? (
        <FrenchText />
      ) : (
        <EnglishText />)}
    </div>
  </div>
);

export default PrivacyPolicy;
