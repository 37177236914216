import React, { useEffect } from 'react';
import { Container } from 'react-bootstrap';
import styles from './PageWrapper.module.scss';

interface PageWrapperProps {
  withBottomBackground: boolean;
}

const PageWrapper: React.FC<PageWrapperProps> = ({
  children, withBottomBackground,
}) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Container className={withBottomBackground ? styles.pageWithBackground : styles.page} fluid>
      {children}
    </Container>
  );
};

export default PageWrapper;
