/* eslint-disable react/jsx-no-literals,react/no-unescaped-entities,max-len */
import React from 'react';

import styles from './FrenchText.module.scss';

const FrenchText: React.FC = () => (
  <>
    <h2>
      Politique de protection des données personnelles ONOFF TELECOM
    </h2>

    <h2>Charte d’utilisation des cookies et traceurs</h2>

    <p><span>Mise à jour le : 30 Juin 2021</span></p>

    <p>
      <span>
        La présente politique de protection des données personnelles a pour but de Vous informer sur la manière dont Nous utilisons Vos données personnelles, et protégeons les données que Vous Nous communiquez lors de l’utilisation
      </span>
    </p>

    <ul>
      <li>
        de toute application téléchargeable sur les plateformes de distribution d’application Apple
        Store, Google Play Store ou Microsoft AppSource (indifféremment pour toute application, l’«
        <strong>Application</strong>
        »)
      </li>
      <li>
        de tout site ou application internet (indifféremment pour tout site ou application internet,
        le «
        <strong>
          Site
        </strong>
        »)
      </li>
    </ul>

    <p>
      exploités par ONOFF TELECOM.
    </p>

    <p>
      Pour les besoins de la présente politique, les définitions des Conditions Générales
      d’Utilisation s’appliquent (
      <a
        href="https://www.onoff.app/conditions-generales-d-utilisation/"
      >
        https://www.onoff.app/conditions-generales-d-utilisation/
      </a>
      {' '}
      &
      {' '}
      <a href="https://onoffbusiness.com/conditions-generales-d-utilisation/">
        https://onoffbusiness.com/conditions-generales-d-utilisation/
      </a>
      ).
    </p>

    <h2>Politique de protection des données personnelles ONOFF TELECOM </h2>

    <h3>1. Objet</h3>

    <p>
      La présente politique de protection des données personnelles s’applique à l’ensemble des
      données personnelles des Utilisateurs de l’Application traitées par ONOFF TELECOM, société par
      actions simplifiée, immatriculée au Registre du Commerce et des Sociétés de Paris sous le
      numéro 832 235 253 et ayant son siège social au 26 boulevard de Bonne Nouvelle 75010, Paris,
      France, en tant que responsable de traitement, dans les conditions prévues aux présentes.
    </p>

    <p>
      ONOFF TELECOM accorde une importance particulière à la protection de Vos données personnelles
      et au respect de Votre vie privée. La présente politique de confidentialité (ainsi que Notre
      charte sur les cookies et traceurs et Nos conditions générales d’utilisation et tout document
      auquel il y est fait référence) vise à Vous informer de la manière dont Nous traitons les
      données personnelles que Vous Nous fournissez et que Nous recueillons dans le cadre de Votre
      utilisation de l’Application, de Notre Site et des services. Nous Vous invitons à lire
      attentivement le présent document pour comprendre Nos pratiques quant au traitement de Vos
      données personnelles et connaître Vos droits en la matière.
    </p>

    <p>
      La présente Politique de confidentialité s’applique à l’Application, au Site ainsi qu’à tous
      les Services édités par ONOFF TELECOM que Vous utilisez (à l’exclusion des services fournis et
      exploités par des tiers, auquel cas, c’est la politique de confidentialité de ces tiers qui
      s’applique et non les présentes stipulations).
    </p>

    <h3>2. Quelles sont les données collectées et traitees par ONOFF TELECOM ?</h3>

    <p>
      ONOFF TELECOM est susceptible de recueillir et d’utiliser des données personnelles Vous
      concernant. Il s’agit d’informations que Vous Nous transmettez directement ou de données que
      Nous recueillons automatiquement.
    </p>

    <p>
      Ceci inclut les informations recueillies lorsque Vous téléchargez et utilisez l’Application,
      notamment lors de la création du Compte Utilisateur, de l’achat des Numéros et de
      l’utilisation des fonctionnalités ou bien lorsque vous utilisez le Site.
    </p>

    <p>
      Lorsque vous créez votre Compte Utilisateur ou souscrivez à l’un des Services, ONOFF TELECOM
      Vous demandera les informations suivantes : Vos nom, prénom, mot de passe, adresse mail, et le
      cas échéant numéro de téléphone, documents d’identité ou prouvant Votre lieu de résidence
      lorsque la réglementation applicable le requiert.
    </p>

    <p>
      Dans le cadre des Services Onoff Business, certaines de ces données font l'objet d'une
      collecte indirecte et sont transmises à ONOFF TELECOM par votre Entreprise : nom, prénom et
      adresse mail.
    </p>

    <p>
      Seule la fourniture de vos nom, prénom, mot de passe, adresse mail (et le cas échéant numéro
      de téléphone, documents d’identité ou prouvant Votre lieu de résidence lorsque la
      réglementation applicable le requiert) est obligatoire pour la création de votre compte.
    </p>

    <p>
      À défaut de les communiquer, ONOFF TELECOM ne sera pas en mesure de Vous fournir les Services
      proposés par l’Application et Vous ne pourrez pas créer de Compte Utilisateur.
    </p>

    <p>
      Par la suite, ONOFF TELECOM est susceptible de collecter et de traiter les informations
      suivantes, dans le cadre de votre utilisation de l’Application et de vos achats :
    </p>

    <ul>
      <li>
        Les numéros de téléphone que Vous avez acquis ;
      </li>
      <li>
        Vos données de trafic (données techniques permettant la transmission des communications, la
        durée de l’appel, les numéros appelés, la catégorie dans laquelle ils ont été placés, etc.)
        ;
      </li>
      <li>
        Le contenu des messages instantanés, SMS, MMS ;
      </li>
      <li>
        Votre carnet d’adresses (coordonnées enregistrées sur Votre terminal), avec Votre
        autorisation ;
      </li>
      <li>
        Les données d’identification électronique de Votre terminal (numéro de carte SIM, numéro
        IMEI, cookies, etc.) ;
      </li>
      <li>
        Vos données de géolocalisation, identifiants de l’appareil et identifiant standard des
        réseaux Wi-Fi détectés.
      </li>
    </ul>

    <p>
      Lorsque Vous contactez le service et support client, signalez un problème relatif à Nos
      Services, demandez de l’assistance ou exercez vos droits sur vos données personnelles ONOFF
      TELECOM collectera également les données personnelles suivantes :
    </p>

    <ul>
      <li>
        Date et heure de la demande ;
      </li>
      <li>
        Nature et motif de la demande
      </li>
    </ul>

    <p>
      Par ailleurs, une preuve de Votre identité peut être demandée dans le cadre de l’exercice de
      Vos droits sur Vos données personnelles pour des raisons de sécurité et de confidentialité.
    </p>

    <p>
      Ces informations sont nécessaires au traitement pour répondre à Vos demandes.
    </p>

    <p>
      Lorsque vous utilisez l’Application et les Services édités par ONOFF, Nous recueillons des
      informations concernant Votre activité sur l’Application :
    </p>

    <ul>
      <li>
        Date d’inscription ;
      </li>
      <li>
        Dates des connexions ;
      </li>
      <li>
        Nombre de transactions ;
      </li>
      <li>
        Notifications reçues ;
      </li>
      <li>
        Adresses IP ;
      </li>
      <li>
        Préférences de notifications, etc.
      </li>
    </ul>

    <p>
      Lorsque vous accédez aux Services et que vous utilisez l’Application et ses fonctionnalités,
      des informations sont collectées et traitées concernant la manière dont Vous les utilisez. La
      plupart de ces informations collectées par ONOFF TELECOM est nécessaire à la fourniture des
      Services. Sans ces données, ONOFF TELECOM ne pourra pas fournir les Services liés à
      l’Application.
    </p>

    <h3>3. Comment Vos données sont-elles utilisées par ONOFF TELECOM ?</h3>

    <p>
      Le traitement de Vos données personnelles poursuit les finalités suivantes :
    </p>

    <ul>
      <li>
        Vous fournir une Application proposant des services innovants de communications et de
        partage de contenus ;
      </li>
      <li>
        Créer, utiliser et conserver Votre Compte Utilisateur ;
      </li>
      <li>
        Assurer la gestion de Vos Numéros, contacts, groupes de conversation, etc. ;
      </li>
      <li>
        Modifier et personnaliser les fonctions et fonctionnalités de l’Application ;
      </li>
      <li>
        Recevoir et traiter les transactions effectuées via l’Application ;
      </li>
      <li>
        Vous fournir Notre assistance et résoudre les éventuels problèmes rencontrés lors de
        l’utilisation de l’Application ;
      </li>
      <li>
        Vous informer des mises à jour de l’Application et de Nos offres et Services ;
      </li>
      <li>
        Solliciter Vos avis ou Votre participation à des enquêtes afin d’améliorer Nos Services ;
      </li>
      <li>
        Respecter Nos obligations légales, réglementaires et administratives ;
      </li>
      <li>
        Eviter les activités potentiellement interdites ou illégales.
      </li>
    </ul>

    <p>
      Vous trouverez ci-dessous les bases juridiques correspondant aux finalités de traitement
      :
    </p>

    <table cellPadding="0" cellSpacing="0" className={styles.Table1}>
      <tr className={styles.Table11}>
        <td className={styles.Table1_A1}>
          <strong>Bases juridiques</strong>
        </td>
        <td className={styles.Table1_A1}>
          <strong>Finalités de traitement</strong>
        </td>
      </tr>

      <tr className={styles.Table11}>
        <td className={styles.Table1_A2}>
          <strong>Exécution du contrat ou de mesures pré-contractuelles</strong>
        </td>
        <td className={styles.Table1_A2}>
          <ul>
            <li>
              Fourniture des services de communications et de partage de contenus ;
            </li>

            <li>
              Création, utilisation et conservation de Votre Compte Utilisateur ;
            </li>

            <li>
              Gestion de Vos Numéros, contacts, groupes de conversation, etc. ;
            </li>

            <li>
              Modification et personnalisation des fonctions et fonctionnalités de l’Application ;
            </li>

            <li>
              Réception et traitement des transactions effectuées via l’Application ;
            </li>

            <li>
              Assistance en cas de problèmes rencontrés lors de l’utilisation de l’Application ;
            </li>

            <li>
              Sollicitation de Vos avis ou Votre participation à des enquêtes afin d’améliorer Nos
              services.
            </li>
          </ul>
        </td>
      </tr>

      <tr className={styles.Table11}>
        <td className={styles.Table1_A3}>
          <strong>Obligations légales</strong>
        </td>
        <td className={styles.Table1_B3}>
          <ul>
            <li>
              Respect des obligations légales, administratives et réglementaires
            </li>
          </ul>
        </td>
      </tr>

      <tr className={styles.Table11}>
        <td className={styles.Table1_A2}>
          <strong>Intérêt légitime</strong>
        </td>
        <td className={styles.Table1_A2}>
          <ul>
            <li>
              Prospection commerciale
              <br />
              Afin de vous proposer des services qui correspondent à vos besoins.
            </li>

            <li>
              Eviter les activités potentiellement interdites ou illégales.
              <br />
              Afin de protéger les intérêts des Utilisateurs qui utilisent l’Application dans le
              respect des Conditions d’Utilisation et de la réglementation ainsi que des intérêts
              d’ONOFF TELECOM.
            </li>
          </ul>
        </td>
      </tr>
    </table>

    <br />

    <h3>4. dans quels cas pouvons-nous partager Vos données personnelles ?</h3>

    <p>
      <h6>a. Communication avec Nos affiliées, partenaires et prestataires</h6>

      <p>
        Nous ne communiquons jamais Vos données personnelles à des tiers sans votre consentement
        préalable et exprès, excepté les cas limitativement énumérés ci-dessous.
      </p>

      <p>
        ONOFF TELECOM peut être amenée à communiquer Vos données personnelles aux tiers intervenant
        pour son compte, pour les seuls besoins du fonctionnement de Nos services, et notamment à
        Nos prestataires ou sous-traitants, chargés d’assurer la gestion, l’hébergement, la
        maintenance et l’administration de l’Application et des services et transactions qui y sont
        associés.
      </p>

      <p>
        Vos données personnelles leur seront communiquées dans le respect des dispositions du
        règlement général sur la protection des données personnelles (n° 2016/679) et dans le cadre
        de conventions permettant d’assurer le respect de la présente politique et de la
        confidentialité de Vos données personnelles.
      </p>

      <p>
        Dans l’hypothèse où ONOFF TELECOM serait amenée à fusionner, à être rachetée ou serait cédée
        à une autre société, tout ou partie de ses activités, Nous pourrions alors partager Vos
        données personnelles avec cette société, y compris avec des sociétés établies en dehors de
        l’Union européenne, sous les conditions et dans la mesure autorisée par la règlementation
        applicable et dans le respect des présentes.
      </p>

      <p>
        Par ailleurs, Nous pouvons être contraints de communiquer Vos données personnelles dans le
        cadre d’une loi ou d’une disposition réglementaire en vigueur, d’une ordonnance judiciaire
        ou d’une réglementation, ou si cette divulgation est nécessaire dans le cadre d’une enquête,
        ou d’une procédure, sur le territoire national ou à l’étranger.
      </p>
    </p>

    <p>
      <h6>b. Transferts vers des pays situés en dehors de l’Union Européenne</h6>

      <p>
        Votre utilisation de l’Application entraîne la transmission de Vos données personnelles
        (nom, prénom, adresse électronique, utilisation des fonctionnalités de l’Application, etc.)
        à des fins d’hébergement, d’administration et d’amélioration de la performance de
        l’Application et des services et des transactions qui y sont associés, à des prestataires
        situés dans des pays non-membres de l’Union européenne, notamment aux États-Unis, dont la
        législation en matière de protection de données personnelles diffère de celle en vigueur au
        sein de l’Union européenne.
      </p>

      <p>
        ONOFF TELECOM a mis en place les garanties prévues par la réglementation applicable pour
        s’assurer d’un niveau de protection suffisant des données personnelles en encadrant les
        transferts par des Clauses Contractuelles Types établies par la Commission Européenne.
      </p>
    </p>

    <h3>5. Communication avec Nos affiliées, partenaires et prestataires</h3>

    <p>
      ONOFF TELECOM prend toutes les mesures organisationnelles et techniques qui s’imposent pour
      protéger Vos données personnelles contre toute manipulation accidentelle ou intentionnelle,
      perte, destruction, communication ou contre tout accès non autorisé à Vos données.
    </p>

    <h3>6. Quels sont les cas dans lequels Onoff Telecom est susceptible de Vous contacter ?</h3>

    <p>
      <h6>a. Informations à propos des produits et services d’ONOFF TELECOM.</h6>
      <p>
        ONOFF TELECOM est susceptible de Vous faire parvenir des offres relatives aux produits,
        services et activités proposés par ONOFF TELECOM. Vous pouvez à tout moment définir ou
        modifier Vos choix relatifs à ces communications :
      </p>
      <ul>
        <li>
          en utilisant les liens de désabonnement insérés dans la rubrique Réglages/Support de
          l’Application ;
        </li>

        <li>
          en nous adressant un email à l’adresse suivante :
          {' '}
          <a href="mailto:support@onoffapp.com">
            support@onoffapp.com.
          </a>
          {' '}
          à partir de
          l’adresse
          email auquel Votre Compte Utilisateur est rattachée ;
        </li>

        <li>
          en utilisant les liens de désabonnement insérés dans Nos newsletters ou emails de
          prospection.
        </li>
      </ul>
    </p>

    <p>
      <h6>b. Fonctionnement des Services</h6>

      <p>
        ONOFF TELECOM peut être amenée à Vous demander périodiquement de fournir des informations
        sur Vos expériences, lesquelles serviront à évaluer et améliorer la qualité de ses services,
        ou Vous demander de noter l’application et les nouvelles fonctionnalités de l’Application.
        Vous n’êtes en aucun cas et en aucune circonstance obligé de fournir de telles données.
      </p>

      <p>
        ONOFF TELECOM peut également Vous contacter pour Vous informer des éventuelles anomalies et
        problèmes liés à ses services, ainsi que pour Vous fournir des informations relatives à
        Votre abonnement (par exemple, notification de l’échéance de Votre abonnement à un numéro
        ONOFF TELECOM).
      </p>
    </p>

    <h3>7. Quels sont vos droits et comment les exercer?</h3>

    <p>
      Conformément à la loi n° 78-17 du 6 janvier 1978, dite loi Informatique et Libertés (la « Loi
      Informatique et Libertés ») et au règlement (UE) 2016/679 du Parlement européen et du Conseil
      du 27 avril 2016, relatif à la protection des personnes physiques à l'égard du traitement des
      données à caractère personnel et à la libre circulation de ces données (le « RGPD »), chaque
      Utilisateur dispose d’un droit d’accès, de rectification et, le cas échéant d’un droit à la
      portabilité et à l’effacement sur ses données personnelles, ainsi que d’opposition au
      traitement ou à sa limitation et du droit de définir des directives relatives au sort de ses
      données personnelles après son décès.
    </p>

    <p>
      Dans le cadre du traitement que nous effectuons Vous disposez :
    </p>

    <ul>
      <li>
        d’un droit d’accès : Vous disposez, dans les termes et conditions de l’article 15 du RGPD,
        du droit d’obtenir la communication - sous une forme accessible - des données personnelles
        Vous concernant que Nous traitons et demander à ce que l’on Vous en communique une copie ;
      </li>

      <li>
        d’un droit de rectification : Vous disposez, dans les termes et conditions de l’article 16
        du RGPD, du droit d’exiger d’ONOFF TELECOM que soient rectifiées, complétées, mises à jour
        ou effacées les données à caractère personnel Vous concernant, qui sont inexactes,
        incomplètes, équivoques, périmées (notamment en cas de changement de situation) ou dont
        l’utilisation, la communication ou la conservation est interdite, ;
      </li>

      <li>
        d’un droit d’effacement : Vous pouvez Nous demander la suppression de vos données
        personnelles dans les conditions prévues à l’article 17 du RGPD notamment lorsque ces
        dernières ne sont plus nécessaires ;
      </li>

      <li>
        d’un droit à la limitation du traitement : Vous pouvez Nous demander de limiter le
        traitement de vos données personnelles dans les conditions prévues à l’article 18 du RGPD ;
      </li>

      <li>
        d’un droit à la portabilité des données : Vous pouvez, dans les termes et conditions de
        l’article 20 du RGPD récupérer dans un format structuré, couramment utilisé et lisible par
        machine les données que Vous Nous avez fournies lorsque ces dernières sont nécessaires au
        contrat ou lorsque Vous avez consenti à l’utilisation de ces données ; vous disposez du
        droit de les transmettre à un autre responsable de traitement,
      </li>

      <li>
        d’un droit de retrait : Vous avez le droit de retirer le consentement donné pour un
        traitement fondé sur cette base. Ce retrait vaut pour l’avenir et ne remet pas en cause la
        licéité des traitements déjà effectués ;
      </li>

      <li>
        d’un droit d’opposition : Vous pouvez Vous opposer au traitement de vos données personnelles
        notamment aux fins de prospection commerciale dans les conditions énoncées à l’article 21 du
        RGPD.
      </li>
    </ul>

    <p>
      Vous pouvez, sous réserve de prouver votre identité, exercer ces droits en nous envoyant un
      message à l’adresse suivante :
      {' '}
      <a href="mailto:support@onoffapp.com">
        support@onoffapp.com.
      </a>
      {' '}
      ou en envoyant un courrier à l’adresse
      postale suivante : à l’attention du Délégué à la protection des données, ONOFF TELECOM - 26
      boulevard de Bonne Nouvelle - 75010 Paris – France. Pour des raisons de confidentialité, en
      cas de doute sur l’identité du demandeur, une preuve d’identité peut être demandée.
    </p>

    <p>
      Par ailleurs, vous pouvez introduire une réclamation auprès d’une autorité de contrôle
      nationale compétente en matière de protection des données.
    </p>

    <p>
      Note pour les utilisateurs français :
    </p>

    <p>
      Vous disposez, conformément à l’article 85 de la Loi Informatique et Libertés, d’un droit de
      définir des directives relatives au sort de Vos données personnelles en cas de décès que Vous
      pouvez exercer en Nous envoyant un message à l’adresse suivante :
      {' '}
      <a href="mailto:support@onoffapp.com">
        support@onoffapp.com.
      </a>
    </p>

    <p>
      Vous pouvez également Vous opposer à faire l’objet de démarchage téléphonique en Vous
      inscrivant à la liste Bloctel sur le site
      {' '}
      <a href="www.bloctel.fr">
        www.bloctel.fr
      </a>
      .
    </p>

    <h3>8. Combien de temps conservons-nous vos données personnelles ?</h3>

    <p>
      Conformément à la réglementation applicable, Nous conservons Vos données personnelles pour une
      durée n’excédant pas celle nécessaire au regard des finalités pour lesquelles elles sont
      traitées.
    </p>

    <p>
      Les données personnelles que Nous recueillons quand Vous téléchargez et utilisez l’Application
      sont conservées pendant la durée nécessaire à la gestion des Services souscrits puis pendant 5
      ans en archives.
    </p>

    <p>
      Les données relatives au trafic collectées dans le cadre de Votre utilisation des Services de
      communications sont conservées conformément aux durées prescrites par la législation
      applicable.
    </p>

    <p>
      Si Vous cessez d’utiliser Votre Compte Utilisateur pendant une période de deux (2) ans, il
      passera à l’état inactif et sera supprimé. Vous pouvez éviter cela en Vous connectant à Votre
      Compte Utilisateur au moins une fois tous les deux (2) ans. Avant de procéder à la suppression
      de Votre Compte Utilisateur, nous vous enverrons une notification par email afin de vous
      donner la possibilité de conserver Votre Compte Utilisateur.
    </p>

    <p>
      Lorsque vous supprimez Votre Compte Utilisateur ou lorsque ce dernier est supprimé en raison
      d’une inactivité, Nous conservons certaines de Vos données personnelles nécessaires afin
      d’assurer le respect de Nos obligations légales et réglementaires. Les autres données
      personnelles associées à Votre Compte Utilisateur sont supprimées.
    </p>

    <h3>9. Si vous avez moins de 18 ans</h3>

    <p>
      Si Vous avez moins de 18 ans, Nous Vous prions de demander l’accord de Vos parents ou
      représentants légaux avant de Nous fournir les données Vous concernant.
    </p>

    <p>
      Ils devront Nous contacter à l’adresse
      {' '}
      <a href="mailto:support@onoffapp.com">
        support@onoffapp.com.
      </a>
      {' '}
      afin de Nous fournir leurs coordonnées (nom, adresse mail, numéro de téléphone) pour qu’ils
      confirment leur accord et afin que Nous puissions les contacter en cas de problème ou pour
      tout complément d’information. A défaut, Vous devez Vous abstenir d’utiliser l’Application.
    </p>

    <h3>10. Notifications</h3>

    <p>
      Votre appareil mobile ainsi que l’Application doivent être paramétrés afin que Vous soyez
      informés d’appels, de discussions et de messages entrants lorsque vous n’êtes pas en train
      d’utiliser l’Application. Si vous ne souhaitez pas bénéficier de cette fonctionnalité, il Vous
      est possible de modifier les paramètres de Votre terminal pour ne plus recevoir de
      notifications de la part de l’Application.
    </p>

    <h3>11. Modifications de la présente politique</h3>

    <p>
      Nous nous réservons le droit de modifier la présente Politique de protection des données
      personnelles à tout moment. Par conséquent, Nous vous recommandons de consulter régulièrement
      cette Politique afin de Vous informer de toute mise à jour de son contenu.
    </p>

    <p>
      La présente politique a été modifiée pour la dernière fois le 30 juin 2021
    </p>

    <h3>12. Comment contacter Onoff Telecom?</h3>

    <p>
      Vous avez la possibilité de nous contacter pour toute question relative à l’utilisation de
      Vos données, ou pour exercer Vos droits à l’adresse email suivante :
      {' '}
      <a href="mailto:support@onoffapp.com">
        support@onoffapp.com.
      </a>
      {' '}
      ou en envoyant un courrier à l’adresse postale suivante : à l’attention du Délégué à la
      protection des données, ONOFF TELECOM - 26 boulevard de Bonne Nouvelle - 75010 Paris –
      France.
    </p>

    <h2>Charte d’utilisation des cookies et traceurs</h2>

    <p>
      ONOFF TELECOM accorde une importance particulière au respect de votre vie privée et vous
      invite à lire la présente Charte d’utilisation des cookies et traceurs (« Charte ») pour
      comprendre comment Nous utilisons les cookies et traceurs sur l’Application et les sites
      internet que nous exploitons.
    </p>

    <p>
      La Charte a pour vocation de compléter la Politique de confidentialité et les Conditions
      Générales d’Utilisation de l’Application.
    </p>

    <p>
      Les termes commençant par une majuscule et qui ne sont pas définis dans la Charte ont la
      signification des termes définis dans les Conditions Générales d’Utilisation.
    </p>

    <h3>1. Champ d'application de la charte</h3>

    <p>La Charte a pour vocation de s’appliquer aux supports suivants : </p>

    <ul>
      <li>
        Tout site Internet exploité par ONOFF TELECOM (indifféremment pour tout site internet, le «
        Site ») ;
      </li>

      <li>
        Toute application mobile exploitée par ONOFF TELECOM disponible notamment sur l’App Store
        d’Apple et le Play Store de Google (indifféremment pour tout application, l’« Application
        »).
      </li>

      <li>
        Toute application exploitée par ONOFF TELECOM utilisable au moyen d’un navigateur Internet
        (Chrome, Edge, Firefox …) ou toute solution permettant l’utilisation de Numéros à travers
        des applications appartenant à des tiers (Microsoft Teams ou Zoom).
      </li>
    </ul>

    <h3>2. Cookies et traceurs</h3>

    <p>
      L’utilisation du Site et de l’Application suppose l’utilisation de cookies et traceurs sur
      Votre terminal. Vous pouvez refuser l’utilisation des cookies et traceurs depuis n’importe
      quel terminal en nous contactant via l’adresse mail suivante:
      {' '}
      <a href="mailto:support@onoffapp.com">
        support@onoffapp.com.
      </a>
      {' '}
      . Vous
      devez savoir que certaines fonctionnalités du Site et de l’Application ne sont disponibles
      qu’en utilisant les cookies et traceurs, et que si Vous choisissez de ne pas les accepter,
      Vous Vous exposez à la possibilité que ces fonctionnalités Vous soient inaccessibles.
    </p>

    <h4>2.1. Que sont les cookies et les traceurs ?</h4>

    <p>
      Les cookies sont de petits fichiers de textes qui sont enregistrés sur Votre appareil, lors
      de l’utilisation de certaines fonctionnalités de l’Application. Pour plus d’informations sur
      les cookies, Nous Vous invitons à consulter :
      http://www.allaboutcookies.org/fr/http://www.allaboutcookies.org/fr/.
    </p>

    <p>
      Les cookies sécurité et fonctionnels sont strictement nécessaires à la navigation sur le
      Site et l’Application, ainsi Vous ne pouvez pas refuser le dépôt de ces cookies.
    </p>

    <h4>2.2. Comment les cookies et traceurs sont-ils utilisés par ONOFF TELECOM ?</h4>

    <p>
      ONOFF TELECOM utilise des cookies de mesure d’audience et traceurs pour réaliser et générer
      des statistiques anonymes de fréquentation du Site et de l’Application, ainsi que des données
      globales sur l’utilisation de l’Application.
    </p>

    <h3>3. Pourquoi ONOFF TELECOM utilise-t-elle des Cookies et Traceurs</h3>

    <p>
      ONOFF TELECOM utilise des cookies sur son Site et des traceurs sur son Application pour les
      finalités listées ci-après.
    </p>

    <p>
      Pour ces finalités, ONOFF TELECOM est susceptible de permettre à des partenaires tiers de
      déposer des cookies ou accéder à des Identifiants et à des cookies sur le disque dur de Votre
      terminal lorsque Vous visitez Notre Site ou utilisez Notre Application.
    </p>

    <p>
      Votre consentement est exigé avant tout dépôt de cookies sauf pour ceux répondant à des
      besoins techniques, ceux-ci étant strictement nécessaires à la navigation sur le Site. De
      même, votre consentement est exigé pour l’utilisation des traceurs sur l’Application et la
      collecte des Identifiants par les outils de traçage. Votre consentement n’est pas requis
      uniquement dans le cas où les traceurs sont strictement nécessaires au fonctionnement ou à la
      sécurité de l’Application.
    </p>

    <h4>3.1 Sur le Site</h4>

    <p>
      Les cookies déposés permettent au Site de Vous fournir une page adaptée, de reconnaître Vos
      précédents réglages, de vous fournir du support et de faciliter Votre navigation.
    </p>

    <p>
      Lorsque vous visitez Notre Site, des cookies sont déposés sur le disque dur de votre terminal.
      Certains sont nécessaires au (i) fonctionnement et à la sécurité de Notre Site et d’autres
      sont utilisés, lorsque Vous y consentez, à (ii) des fins statistiques.
    </p>

    <p>
      <strong>
        Besoins techniques :
      </strong>
    </p>

    <p>
      Lorsque vous consultez le Site des cookies sécurité sont déposés. Ils peuvent être utilisés à
      des fins d’authentification ou de sécurité. Ils sont obligatoires pour permettre certaines
      fonctionnalités du Site et afin d’en assurer la sécurité.
    </p>

    <p>
      Des cookies fonctionnels sont également déposés et permettent d’améliorer et d’optimiser votre
      expérience sur le Site.
    </p>

    <p>
      <strong>
        Les cookies sécurité et fonctionnels sont strictement nécessaires à la navigation sur le
        Site, ainsi vous ne pouvez pas refuser le dépôt de ces cookies.
      </strong>
    </p>

    <table cellPadding="0" cellSpacing="0" className={styles.Table1}>
      <tr className={styles.Table11}>
        <td className={styles.Table1_A1}>
          <strong>Nom du cookie</strong>
        </td>
        <td className={styles.Table1_A1}>
          <strong>Entreprise</strong>
        </td>
        <td className={styles.Table1_A1}>
          <strong>Domaine</strong>
        </td>
        <td className={styles.Table1_A1}>
          <strong>Finalité</strong>
        </td>
        <td className={styles.Table1_A1}>
          <strong>Durée</strong>
        </td>
      </tr>

      <tr className={styles.Table11}>
        <td className={styles.Table1_A2}>
          User locale
        </td>
        <td className={styles.Table1_A2}>
          Onoff
        </td>
        <td className={styles.Table1_A2}>
          <a href="web.onoffbusiness.com">web.onoffbusiness.com</a>
        </td>
        <td className={styles.Table1_A2}>
          Avec ce cookie il est possible de stocker la langue préférée de l’utilisateur. Nous
          pouvons donc afficher les pages du site dans cette langue.
        </td>
        <td className={styles.Table1_A2}>
          Session (supprimé après la fermeture du navigateur)
        </td>
      </tr>

      <tr className={styles.Table11}>
        <td className={styles.Table1_A3}>
          User id
        </td>
        <td className={styles.Table1_A3}>
          Onoff
        </td>
        <td className={styles.Table1_A3}>
          <a href="web.onoffbusiness.com">web.onoffbusiness.com</a>
        </td>
        <td className={styles.Table1_A3}>
          Ce cookie permet d’identifier l’utilisateur sans qu’il ait besoin de se reconnecter
          manuellement.
        </td>
        <td className={styles.Table1_A3}>
          1 an
        </td>
      </tr>

      <tr className={styles.Table11}>
        <td className={styles.Table1_A2}>
          Checkout
        </td>
        <td className={styles.Table1_A2}>
          Checkout
        </td>
        <td className={styles.Table1_A2}>
          <a href="https://www.checkout.com/">https://www.checkout.com/</a>
        </td>
        <td className={styles.Table1_A2}>
          Ces cookies permettent d’identifier les informations relatives au paiement comme par
          exemple: l’utilisateur au sein de checkout, la zone géographique,..
        </td>
        <td className={styles.Table1_A2}>
          <ul>
            <li>
              _gcl_au, _gid, RT (1 jour)
            </li>
            <li>
              optimizelyEndUserId (6mois)
            </li>
            <li>
              hjid (1 an)
            </li>
            <li>
              OptanonAlertBoxClosed (1an)
            </li>
            <li>
              _hp2_id (13 mois)
            </li>
            <li>
              OptanonConsent, (1 an)
            </li>
            <li>
              _uetvid (16 jours)
            </li>
            <li>
              ei_client_id (730 jours)
            </li>
            <li>
              _ga (2 ans)
            </li>
          </ul>
        </td>
      </tr>

      <tr className={styles.Table11}>
        <td className={styles.Table1_A3}>
          Zendesk
        </td>
        <td className={styles.Table1_A3}>
          Zendesk
        </td>
        <td className={styles.Table1_A3}>
          <a href="https://www.zendesk.com/">https://www.zendesk.com/</a>
        </td>
        <td className={styles.Table1_A3}>
          Ce cookie permet au support de récupérer des informations relatives à l’utilisateur afin
          de mieux l’assister
        </td>
        <td className={styles.Table1_A3}>
          -
        </td>
      </tr>

      <tr className={styles.Table11}>
        <td className={styles.Table1_A2}>
          Recaptcha
        </td>
        <td className={styles.Table1_A2}>
          Google
        </td>
        <td className={styles.Table1_A2}>
          <a href="https://www.google.com/recaptcha/about/">
            https://www.google.com/recaptcha/about/
          </a>
        </td>
        <td className={styles.Table1_A2}>
          Ce cookie permet de vérifier si l’utilisateur n’est pas un “bot” (robot)
        </td>
        <td className={styles.Table1_A2}>
          6 mois
        </td>
      </tr>
    </table>

    <p>
      <strong>
        Besoins analytiques :
      </strong>
    </p>

    <p>
      ONOFF TELECOM utilise des Cookies de mesure d’audience qui sont utilisés pour obtenir des
      informations sur le parcours des Utilisateurs, pour analyser le trafic sur Notre Site et à des
      fins de mesures d’audience (par exemple, les cookies collectent des informations relatives aux
      pages visitées : nombres de visites, etc.). Les informations collectées sont anonymes et
      agrégées. Cela Nous permet d’améliorer notre Site selon les demandes et besoins des
      Utilisateurs.
    </p>

    <p>
      <strong>
        Votre consentement est demandé pour le dépôt de cookies à des fins analytiques.
      </strong>
    </p>

    <table cellPadding="0" cellSpacing="0" className={styles.Table1}>
      <tr className={styles.Table11}>
        <td className={styles.Table1_A1}>
          <strong>Nom du cookie</strong>
        </td>
        <td className={styles.Table1_A1}>
          <strong>Entreprise</strong>
        </td>
        <td className={styles.Table1_A1}>
          <strong>Domaine</strong>
        </td>
        <td className={styles.Table1_A1}>
          <strong>Finalité</strong>
        </td>
      </tr>

      <tr className={styles.Table11}>
        <td className={styles.Table1_A2}>
          Snap Pixel
        </td>
        <td className={styles.Table1_A2}>
          Snapchat
        </td>
        <td className={styles.Table1_A2}>
          <a
            href="https://forbusiness.snapchat.com/measurement/"
          >
            https://forbusiness.snapchat.com/measurement/
          </a>
        </td>
        <td className={styles.Table1_A2}>
          Avec le Snap Pixel, les annonceurs peuvent suivre le parcours de leurs clients.
        </td>
      </tr>

      <tr className={styles.Table11}>
        <td className={styles.Table1_A3}>
          Taboola
        </td>
        <td className={styles.Table1_A3}>
          Taboola
        </td>
        <td className={styles.Table1_A3}>
          <a href="https://www.taboola.com/fr/">https://www.taboola.com/fr/</a>
        </td>
        <td className={styles.Table1_A3}>
          Le Pixel Taboola rassemble des données pour nous permettre d'avoir une meilleure
          compréhension des actions que les utilisateurs entreprennent sur notre site.
        </td>
      </tr>

      <tr className={styles.Table11}>
        <td className={styles.Table1_A2}>
          Facebook Pixel
        </td>
        <td className={styles.Table1_A2}>
          Facebook
        </td>
        <td className={styles.Table1_A2}>
          <a
            href="https://www.facebook.com/business/gdpr"
          >
            https://www.facebook.com/business/gdpr
          </a>
        </td>
        <td className={styles.Table1_A2}>
          Identifie les visiteurs en provenance de publications Facebook
        </td>
      </tr>

      <tr className={styles.Table11}>
        <td className={styles.Table1_A3}>
          Google Ads
        </td>
        <td className={styles.Table1_A3}>
          Google
        </td>
        <td className={styles.Table1_A3}>
          <a href="https://www.google.fr">https://www.google.fr</a>
        </td>
        <td className={styles.Table1_A3}>
          Google Ads est la régie publicitaire du moteur de recherche Google. Il permet de réaliser
          des campagnes de pub via un système d’annonces et d’afficher ces annonces dans les
          résultats des moteurs de recherches en fonction des mots clés tapés par les internautes.
        </td>
      </tr>

      <tr className={styles.Table11}>
        <td className={styles.Table1_A2}>
          Google Analytics
        </td>
        <td className={styles.Table1_A2}>
          Google
        </td>
        <td className={styles.Table1_A2}>
          <a href="https://www.google.fr">https://www.google.fr</a>
        </td>
        <td className={styles.Table1_A2}>
          Permet d'analyser les statistiques de consultation de notre site
        </td>
      </tr>

      <tr className={styles.Table11}>
        <td className={styles.Table1_A3}>
          Google Conversion Tracking
        </td>
        <td className={styles.Table1_A3}>
          Google
        </td>
        <td className={styles.Table1_A3}>
          <a href="https://www.google.fr">https://www.google.fr</a>
        </td>
        <td className={styles.Table1_A3}>
          Statistiques d'utilisation du suivi des conversions Google
        </td>
      </tr>

      <tr className={styles.Table11}>
        <td className={styles.Table1_A2}>
          Diffuse nos annonces publicitaires sur le réseau Google
        </td>
        <td className={styles.Table1_A2} />
        <td className={styles.Table1_A2}>
          <a
            href="https://privacy.google.com/intl/fr_fr/businesses/compliance/#!?modal_active=none"
          >
            https://privacy.google.com/intl/fr_fr/businesses/compliance/#!?modal_active=none
          </a>
        </td>
        <td className={styles.Table1_A2}>
          Diffuse nos annonces publicitaires sur le réseau Google
        </td>
      </tr>

      <tr className={styles.Table11}>
        <td className={styles.Table1_A3}>
          Bing
        </td>
        <td className={styles.Table1_A3}>
          Microsoft
        </td>
        <td className={styles.Table1_A3}>
          <a
            href="https://www.bing.fr"
          >
            https://www.bing.fr
          </a>
        </td>
        <td className={styles.Table1_A3}>
          Mesure l’efficacité des campagnes sponsorisées sur Bing
        </td>
      </tr>

      <tr className={styles.Table11}>
        <td className={styles.Table1_A2}>
          Linkedin
        </td>
        <td className={styles.Table1_A2}>
          Linkedin
        </td>
        <td className={styles.Table1_A2}>
          <a
            href="https://www.linkedin.com"
          >
            https://www.linkedin.com
          </a>
        </td>
        <td className={styles.Table1_A2}>
          Identifie les visiteurs en provenance de Linkedin
        </td>
      </tr>
    </table>

    <h4>3.2 Sur l'Application</h4>

    <p>
      Il n’y a pas de cookies déposés par les applications mobiles. En revanche, l’Application
      accède et collecte les Identifiants uniques de Votre appareil afin de le reconnaître d’une
      visite sur l’autre.
    </p>

    <p>
      Des outils internes et partenaires ainsi que des traceurs ont été installés sur l’Application
      afin d’accéder et de collecter les Identifiants.
    </p>

    <p>
      Ces traceurs permettent à ONOFF TELECOM de :
    </p>

    <ul>
      <li>
        s’assurer du bon fonctionnement de l’Application,
      </li>

      <li>
        faire fonctionner certaines fonctionnalités ;
      </li>

      <li>
        si Vous y avez consenti, les utiliser à des fins publicitaires ou d’analyse.
      </li>
    </ul>

    <p>
      <strong>
        Besoins techniques :
      </strong>
    </p>

    <p>
      Lorsque Vous consultez l’Application des traceurs dits de sécurité collectent et traitent
      des données de Votre appareil. Ils peuvent être utilisés à des fins d’authentification ou de
      sécurité. Ils sont obligatoires pour permettre certaines fonctionnalités de l’Application et
      afin d’en assurer la sécurité.
    </p>

    <p>
      Des traceurs dits fonctionnels sont également utilisés et permettent d’améliorer et
      d’optimiser Votre expérience sur l’Application ou sont nécessaires pour l’utilisation de
      certaines fonctionnalités.
    </p>

    <p>
      Les traceurs de sécurité et fonctionnels sont strictement nécessaires à la fourniture des
      services sur l’Application, ainsi vous ne pouvez pas refuser la collecte des Identifiants et
      l’utilisation des traceurs de sécurité ou fonctionnels.
    </p>

    <p>
      <strong>
        Besoins analytiques :
      </strong>
    </p>

    <p>
      ONOFF TELECOM utilise des traceurs de mesure d’audience pour obtenir des informations sur le
      parcours des Utilisateurs, pour analyser le trafic sur l’Application et à des fins de mesures
      d’audience. Cela permet à ONOFF TELECOM d’améliorer l'Application selon les demandes et
      besoins des Utilisateurs.
    </p>

    <p>
      Votre consentement est demandé pour la collecte des Identifiants et l’utilisation des traceurs
      à des fins analytiques.
    </p>

    <table cellPadding="0" cellSpacing="0" className={styles.Table1}>
      <tr className={styles.Table11}>
        <td className={styles.Table1_A1}>
          <strong>Nom du traceur</strong>
        </td>
        <td className={styles.Table1_A1}>
          <strong>Entreprise</strong>
        </td>
        <td className={styles.Table1_A1}>
          <strong>Finalité</strong>
        </td>
      </tr>

      <tr className={styles.Table11}>
        <td className={styles.Table1_A2}>
          Firebase Analytics
        </td>
        <td className={styles.Table1_A2}>
          Google
        </td>
        <td className={styles.Table1_A2}>
          Mesures d'application et d'audience, pour suivre les actions des utilisateurs au sein de
          l'application afin d'améliorer l'expérience utilisateur, collecter des métriques pour
          analyser le succès des fonctionnalités, activer des fonctionnalités pour des publics
          spécifiques, collecter les identifiants des utilisateurs pour pouvoir cibler des publics
          spécifiques.
        </td>
      </tr>

      <tr className={styles.Table11}>
        <td className={styles.Table1_A3}>
          Google Analytics
        </td>
        <td className={styles.Table1_A3}>
          Google
        </td>
        <td className={styles.Table1_A3}>
          Mesures d'application et d'audience, pour suivre les actions des utilisateurs au sein de
          l'application afin d'améliorer l'expérience utilisateur, collecter des métriques pour
          analyser le succès des fonctionnalités, activer des fonctionnalités pour des publics
          spécifiques, collecter les identifiants des utilisateurs pour pouvoir cibler des publics
          spécifiques.
        </td>
      </tr>

      <tr className={styles.Table11}>
        <td className={styles.Table1_A2}>
          Branch analytics
        </td>
        <td className={styles.Table1_A2}>
          Branch.io
        </td>
        <td className={styles.Table1_A2}>
          Mesures d'application et d'audience, pour suivre les actions des utilisateurs au sein de
          l'application afin d'améliorer l'expérience utilisateur, collecter des métriques pour
          analyser le succès des fonctionnalités, activer des fonctionnalités pour des publics
          spécifiques, collecter les identifiants des utilisateurs pour pouvoir cibler des publics
          spécifiques.
        </td>
      </tr>
    </table>

    <h3>4. Gestion des Cookies et traceurs </h3>

    <h4>4.1 Sur le site</h4>

    <p>
      Quand Vous arrivez pour la première fois sur le Site, un bandeau cookie Vous propose
      d’accepter ou de refuser les Cookies Web qui ne sont pas essentiels au fonctionnement stable
      du Site.
    </p>

    <p>
      A défaut de retrait de Votre consentement, celui-ci est valable pour une durée 6 mois. Pendant
      cette période, le bandeau cookie ne Vous sera plus présenté lors de Vos visites sur le Site.
      Lorsque le délai est écoulé, le bandeau cookie sera affiché de nouveau et Vous proposera
      d’accepter ou de refuser le dépôt de cookies.
    </p>

    <p>
      Le consentement que Vous fournissez pour le dépôt des cookies analytiques sur le Site peut
      être retiré à tout moment. Il Vous suffit de cliquer sur le lien prévu à cet effet.
    </p>

    <p>
      Attention, ONOFF TELECOM n’est pas en mesure de supprimer les cookies tiers ayant été déposés
      préalablement au retrait de Votre consentement.
    </p>

    <p>
      Le retrait de Votre consentement entraînera l’impossibilité pour ONOFF TELECOM de lire et
      d’exploiter les informations contenues dans les cookies déjà déposés sur Votre navigateur.
    </p>

    <p>
      Cependant, il Vous est également possible de supprimer directement ces cookies via Votre
      navigateur.
    </p>

    <p>
      <strong>
        NOUS NE VOUS RECOMMANDONS PAS DE BLOQUER ET DÉSACTIVER TOUS LES COOKIES DANS LES
        PARAMÈTRES DE
        VOTRE NAVIGATEUR.
      </strong>
      {' '}
      En effet, si vous bloquez tous les cookies, Notre Site pourrait
      ne pas
      fonctionner correctement et il est possible que Vous ne puissiez pas utiliser certaines de ses
      fonctionnalités.
    </p>

    <h4>4.2 Sur l'Application</h4>

    <p>
      Lors de l’inscription sur l’Application, un écran de consentement aux traceurs Vous propose
      d’accepter, de refuser ou de paramétrer l’utilisation des traceurs qui ne sont pas essentiels
      au fonctionnement stable de l’Application
    </p>

    <p>
      En cas de nouvelle connexion sur l’Application après une déconnexion, l’écran sera affiché de
      nouveau et Vous proposera d’accepter, de refuser ou de paramétrer l’utilisation de traceurs.
    </p>

    <p>
      Le consentement que Vous fournissez pour l’utilisation de traceurs analytiques et
      publicitaires peut être retiré à tout moment.
    </p>

    <h3>5. Comment contacter Onoff Telecom?</h3>

    <p>
      Vous avez la possibilité de nous contacter pour toute question relative à l’utilisation des
      cookies et traceurs à l’adresse email suivante :
      {' '}
      <a href="mailto:support@onoffapp.com">support@onoffapp.com</a>
      {' '}
      ou en envoyant un courrier à l’adresse postale suivante : à l’attention du Délégué à la
      protection des données, ONOFF TELECOM - 26 boulevard de Bonne Nouvelle - 75010 Paris – France.
    </p>
  </>
);

export default FrenchText;
